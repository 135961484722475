<template>
  <div class="box">
    <el-upload
      class="upload-execl"
      :action="uploadUrl"
       accept='.xls,.xlsx'
      :show-file-list="false"
      :headers="headers"
      :on-success="handleSuccess"
      :on-progress="handleProgress"
      :on-error="handleError"
    >
      <el-button
        type="primary"
        size="medium"
      >
        导入用户
      </el-button>
    </el-upload>

    <div class="box-toolbar">
      <!-- <el-button
            v-if="$can('user/export')"
            type="primary"
            size="medium"
          >
            导入用户
          </el-button> -->
      <div class="box-item">
        <el-input
          placeholder="手机号码\用户名\ID"
          v-model="content"
          class="input-with-select"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      border
      height="70vh"
      v-loading="loading"
      :stripe="true"
      :data="lists"
        @selection-change="handleSelectionChange"
    >
     <el-table-column
      type="selection"
      width="55">
    </el-table-column>
      <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="Id"
      >
      </el-table-column>
      <el-table-column
        prop="user_name"
        width="150"
        label="用户名称"
      >
      </el-table-column>
      <el-table-column
        width="60"
        prop="sex"
        label="性别"
      >
      </el-table-column>
      <el-table-column
        prop="br"
        width="150"
        label="生日"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        width="150"
        label="电话号码"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="integral"
        label="成长值"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="can_integral"
        label="可用积分"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="money"
        label="储值余额"
      >
      </el-table-column>

      <el-table-column
        align="center"
        width="179"
        prop="status"
        label="状态"
      >
        <template slot-scope="scope">
          <el-button
            plain
            size='mini'
            type="primary"
            v-if="scope.row.status == 1"
          >已绑定</el-button>
          <el-button
            plain
            size="mini"
            type="danger"
            v-if="scope.row.status == 0"
          >未绑定</el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="179"
        class-name="text-mono"
        prop="level_name"
        label="会员类型"
      >
      </el-table-column>
      <el-table-column
        class-name="text-mono"
        width="130"
        prop="created_at"
        label="导入时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            plain
            type="danger"
            size="mini"
            @click="delU(scope.row)"
          >
            删除
          </el-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="bp-pages">
      <div class="shop-i-bottom">
          <el-button
          type="danger"
          plain
          @click.native="delAll"
        >
          删除所选
        </el-button>
      <page
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@admin/services/UserService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import flatry from '@admin/utils/flatry'
import Auth from '@admin/utils/auth'

export default {
  name: 'BackUpUser',
  mixins: [pagination],
  components: { Page },
  data () {
    return {
      uploadUrl: '',

      searchType: [
        {
          id: 0,
          name: '手机号码'
        },
        {
          id: 1,
          name: '用户名'
        }
      ],
      content: '',
      headers: {
        // 上传图片的请求头部
        'X-Access-Token': Auth.getAccessToken()
      },
      loading: true,
      function: function (param) {
        return UserService.allExport(param)
      },
      ListArr: []
    }
  },
  async mounted () {
    this.uploadUrl = Auth.getHttpApiUrl() + 'user/export'
  },
  methods: {
    handleError (res) {
      this.loading = false
      let rg = /"msg":"(.*)","data"/ig
      const msg = rg.exec(res)
      this.$message.error(msg[1] || '上传失败')
    },
    handleProgress (res) {
      this.loading = true
    },
    handleSelectionChange (val) {
      this.ListArr = val
    },
    handleSuccess (response) {
      this.loading = false
      if (response.success === true) {
        this.avatarUrl = response.data

        this.$message.success(response.msg)

        this.searchGo()
      }
    },
    async searchGo () {
      this.page = 1
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        content: this.content
      }
      this.where = { content: this.content }
      const data = await this.function(param)

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    },
    async delAll () {
      if (this.ListArr.length <= 0) {
        this.$message.warning('请选择需要删除的数据')
        return
      }
      this.$deleteDialog('所选数据', async action => {
        if (action === 'confirm') {
          const { data } = await flatry(UserService.deleteAll(this.ListArr))
          if (data) {
            for (let i = 0; i < this.ListArr.length; i++) {
              for (let j = 0; j < this.lists.length; j++) {
                if (this.ListArr[i].id === this.lists[j].id) {
                  this.lists.splice(j, 1)
                  break
                }
              }
            }
            this.$message.success(data.msg)
          }
        }
      })
    },
    async delU (item) {
      this.$deleteDialog(item.user_name, async action => {
        if (action === 'confirm') {
          const { data } = await flatry(UserService.deleteE(item))
          if (data) {
            for (let j = 0; j < this.lists.length; j++) {
              if (item.id === this.lists[j].id) {
                this.lists.splice(j, 1)
                break
              }
            }
            this.$message.success(data.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.upload-execl {
    float: left;
}
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}
</style>
